export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const COMPANY_ID = 'COMPANY_ID';
export const EMPLOYEE_ID = 'EMPLOYEE_ID';
export const ESIGNATURE_HOST_API_BASE_URL = 'ESIGNATURE_HOST_API_BASE_URL';
export const ESIGNATURE_EMPLOYEE_SEARCH_URI = 'ESIGNATURE_EMPLOYEE_SEARCH_URI';
export const SEARCH_EMPLOYEES_DEBOUNCE_TIME = 500;
export const EMPLOYEE_SELECTION_LIMIT = 25;
export const EMPLOYEE_SEARCH_PAGINATION_LIMIT = 50;
export const MAX_FILE_SIZE = 40000000;
export const USER_CONTEXT_ID = 'USER_CONTEXT_ID';
