import axios, { AxiosError } from "axios";
import { getAccessToken } from "./sessionStorageItems";
import { User } from "../Common/Types/User";

export async function getUser(contextId: string): Promise<User> {
    let path = `/user-context/${contextId}`
    let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;
    return await axios
        .get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAccessToken(),
            },
        })
        .then((response) => {
            return response.data as User;
        })
        .catch((error: AxiosError) => {
            throw error;
        });
}