import { CloseButton, IconComponent } from '@asuresoftware/asure.design-system';
import { useCallback, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

import './Dropzone.scss';

export interface DropzoneProps {
	file?: File;
	setFile: (file: File | undefined) => void;
	accept?: Accept;
	maxSize?: number;
}

export function Dropzone({ file, setFile, accept, maxSize }: DropzoneProps) {
	const [errorMessage, setErrorMessage] = useState<string>();

	const onDrop = useCallback(async (files: File[]) => {
		setFile(files[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDropRejected = useCallback(async (fileRejections: FileRejection[]) => {
		if (fileRejections[0].errors[0].code === 'file-too-large') {
			setErrorMessage(`File exceeds the max file size of ${maxSize && maxSize / 1000000}MB`);
		} else if (fileRejections[0].errors[0].code === 'file-invalid-type') {
			setErrorMessage('Invalid file type');
		} else {
			setErrorMessage(`${fileRejections[0].errors[0].message}`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getRootProps, getInputProps, isDragReject, isDragAccept } = useDropzone({
		onDrop,
		onDropRejected,
		multiple: false,
		accept,
		maxSize,
	});

	return (
		<>
			{file ? (
				<div
					className={`drop-zone d-flex flex-column align-items-center justify-content-center position-relative mb-5 accepted-file`}
				>
					<div className="close-button pt-1 pr-2 w-100 d-flex justify-content-end position-absolute">
						<CloseButton
							className="p-0"
							iconStyle="light"
							onClick={() => {
								setErrorMessage(undefined);
								setFile(undefined);
							}}
							size="sm"
							textColor="text-dark"
						/>
					</div>
					<div className="d-flex flex-column align-items-center p-3">
						<IconComponent icon="file" lib="fal" size="2x" />
						<h5 className="mt-2 mb-1">{file.name}</h5>
					</div>
				</div>
			) : (
				<div
					className={`drop-zone position-relative unselected-file d-flex flex-column justify-content-center align-items-center p-3 mb-5 ${
						isDragReject || (!isDragAccept && errorMessage) ? 'rejected-file' : ''
					} ${isDragAccept ? 'accepted-file' : ''}`}
					{...getRootProps()}
				>
					<input data-testid="drop-input" {...getInputProps()} />
					<IconComponent icon="upload" lib="fal" size="2x" />
					<h5 className="mt-2 mb-1">Drop a document here</h5>
					<span>
						or{' '}
						<span data-testid="select" className="btn-link">
							choose a file
						</span>
					</span>
					<div className="text-center accepted-files w-100 d-flex flex-column justify-content-center">
						{(maxSize || accept) && <br />}
						{maxSize && (
							<div className="w-100 d-flex justify-content-center align-items-center mb-2">
								<h6 className="text-muted mb-0 mr-1">Max file size: </h6>
								<span className="text-muted">{maxSize && maxSize / 1000000} MB</span>
							</div>
						)}
						{accept && (
							<div className="w-100 d-flex flex-column justify-content-center align-items-center mb-2">
								<h6 className="text-muted mb-0 mr-1">Accepted file types:</h6>
								<span className="text-muted">{`${
									accept && Object.values(accept).flat(1).join(' ')
								}`}</span>
							</div>
						)}
					</div>
					<p className={`mb-0 rejected-file-message text-center w-100`}>
						{errorMessage && !isDragAccept && errorMessage}
					</p>
				</div>
			)}
		</>
	);
}
