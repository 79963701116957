import { ESignatureType } from '../UploadDocumentsModal/UploadDocumentsModal';
import { ESignatureOption } from './ESignatureOption';
import './ESignatureOptions.scss';

export interface ESignatureOptionsProps {
  documentType: 'company' | 'employee';
  selectedOption: ESignatureType;
  setSelectedOption: (value: ESignatureType) => void;
}

export function ESignatureOptions({
  documentType,
  selectedOption,
  setSelectedOption,
}: ESignatureOptionsProps) {
  return (
    <fieldset className="esignature-options d-flex flex-column justify-content-start mb-3">
      <legend className="bx--label mb-3">Signature options</legend>
      <ESignatureOption
        eSignatureType={ESignatureType.None}
        selectedOption={selectedOption}
        onClick={() => setSelectedOption(ESignatureType.None)}
      />
      {documentType === 'company' && (
        <ESignatureOption
          eSignatureType={ESignatureType.Enhanced}
          selectedOption={selectedOption}
          onClick={() => setSelectedOption(ESignatureType.Enhanced)}
          disabled={documentType !== 'company'}
        />
      )}
    </fieldset>
  );
}
