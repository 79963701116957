import { AsureCloudButton, Modal } from '@asuresoftware/asure.design-system';
import { useState, useEffect, useCallback } from 'react';
import {
	useDocumentState,
	setError,
	setUploading,
	setDocumentModalActive,
	addPendingDocument,
	setCurrentDocument,
	setPollCount
} from '../../Context/DocumentContext';
import { AxiosError } from 'axios';
import { Dropzone } from '../Dropzone/Dropzone';
import { ESignatureOptions } from '../ESignatureOptions/ESignatureOptions';
import * as actions from '../../Services/services';
import { MAX_FILE_SIZE } from '../../Common/Constants';

export interface UploadDocumentsModalProps {
  setDocumentRedirect: (documentRedirect: any) => void;
}

export enum ESignatureType {
  None,
  Enhanced,
}

export function UploadDocumentsModal({
  setDocumentRedirect,
}: UploadDocumentsModalProps) {
  const [page, setPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState<ESignatureType>(
    ESignatureType.None
  );
  const [s3PresignedUrl, setS3PresignedUrl] = useState<string>('');
  const [documentId, setDocumentId] = useState<string>('');
  const [fileTitle, setFileTitle] = useState<string>('');
  const [file, setFile] = useState<File>();
  const { state, dispatch } = useDocumentState();
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const clearForm = useCallback(() => {
    setFile(undefined);
    setFileTitle('');
    setPage(0);
    setS3PresignedUrl('');
    setDocumentId('');
    setSelectedOption(ESignatureType.None);
  }, [setFile, setFileTitle, setPage, setS3PresignedUrl, setDocumentId]);
  const putS3File = useCallback(async () => {
    const fileUploadCallback: any = await actions.uploadFileToS3(
      s3PresignedUrl,
      file
    );
    if (fileUploadCallback instanceof AxiosError) {
      dispatch(setError(fileUploadCallback));
    }
    dispatch(setUploading(false));
    if (selectedOption === ESignatureType.None) {
      dispatch(addPendingDocument(documentId));
      dispatch(setPollCount(0));
      clearForm();
      if (state.documentType === 'company') {
        setDocumentRedirect('/');
      } else {
        setDocumentRedirect('/employee');
      }
    }
  }, [
    s3PresignedUrl,
    file,
    selectedOption,
    state,
    documentId,
    setDocumentRedirect,
    clearForm,
    dispatch,
  ]);

	const openDocumentEdit = useCallback(() => {
		setDocumentRedirect({
			url: '/document',
			state: { documentId: documentId, method: 'edit', fileTitle: fileTitle },
		});
		clearForm();

	}, [documentId, fileTitle, setDocumentRedirect, clearForm]);

  const fetchPresignedUrl = useCallback(async () => {
    const isEsig = selectedOption === ESignatureType.Enhanced ? true : false;
    const response =
      state.documentType === 'company'
        ? await actions.createCompanyDocument(fileTitle, file, isEsig)
        : await actions.createEmployeeDocument(fileTitle, file, isEsig);
		if (response instanceof AxiosError) {
			dispatch(setError(response));
		} else {
			setS3PresignedUrl(response.s3PresignedUrl);
			setDocumentId(response.documentId);
			dispatch(setCurrentDocument(response.documentId));
		}
	}, [file, fileTitle, selectedOption, state.documentType, dispatch]);

  const handleUpload = async () => {
    dispatch(setDocumentModalActive(false));
    dispatch(setUploading(true));
    await fetchPresignedUrl();
  };

  const checkFileTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      ((event.type === 'blur' || event.type === 'focus') &&
        (!event.target.value || event.target.value.length === 0)) ||
      ((event.type === 'blur' || event.type === 'focus') &&
        event.target.value &&
        (event.target.value.length  && event.target.value.length < 255 )&&
        !event.target.value.match(/^[^*&%/]+$/gi)) ||
      (event.type === 'change' && !event.target.value.match(/^[^*&%/]+$/gi))
    ) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
    setFileTitle(event.target.value);
  };

  useEffect(() => {
    if (state.uploading && s3PresignedUrl !== '') {
      putS3File();
    }
  }, [state.uploading, s3PresignedUrl, putS3File]);
  useEffect(() => {
    if (
      !state.documentModal &&
      !state.uploading &&
      selectedOption === ESignatureType.Enhanced
    ) {
      openDocumentEdit();
    }
  }, [state.documentModal, selectedOption, state.uploading, openDocumentEdit]);

  return (
    <Modal
      mobileFullScreen
      open={state.documentModal}
      size={page === 2 ? 'lg' : 'md'}
      onClose={() => {
        clearForm();
				dispatch(setDocumentModalActive(false));
      }}
      header="Add document"
      footerContent={
        page === 2 ? undefined : (
          <div className="d-flex justify-content-end pb-1">
            {page === 1 && (
              <AsureCloudButton
                className="mr-3"
                state="secondary"
                text="Back"
                onClick={() => {
                  setPage(0);
                }}
              />
            )}
            {page !== 2 && (
              <AsureCloudButton
                disabled={!fileTitle || !file || isInvalid}
                state="primary"
                text={
                  page === 0 && state.documentType === 'company'
                    ? 'Next'
                    : 'Upload'
                }
                onClick={() => {
                  if (page === 0 && state.documentType === 'company') {
                    setPage(1);
                  }
                  if (
                    (page === 0 && state.documentType === 'employee') ||
                    (state.documentType === 'company' && page === 1)
                  ) {
                    handleUpload();
                  }
                }}
              />
            )}
          </div>
        )
      }
    >
      {page === 0 && (
        <>
          <label htmlFor="filetext-input" className="required">
            Title
          </label>
          <input
            id="filetext-input"
            className={'mb-0 fileTitle' + (isInvalid ? ' invalid' : ' ')}
            onChange={(event) => checkFileTitle(event)}
            onBlur={(event) => {
              checkFileTitle(event);
            }}
            onFocus={(event) => checkFileTitle(event)}
            value={fileTitle}
            placeholder="Title of the file"
            required
            type="text"
            name="Title"
            aria-label="Title"
            maxLength={255}
          />
          <p className="invalid text-small">
            {isInvalid &&
              (fileTitle.length > 0 && fileTitle.length < 255
                ? 'File title cannot include the special characters: *&%/'
                : fileTitle.length === 0 ? 'File title is required' : 'File title cannot exceed 255 characters')}
          </p>
          <Dropzone
            file={file}
            setFile={setFile}
            accept={{
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
              'application/pdf': ['.pdf'],
              'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
                ['.ppsx'],
              'application/vnd.ms-powerpoint': ['.ppt'],
              'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                ['.pptx'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'image/gif': ['.gif'],
              'application/vnd.ms-excel': ['.xls'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
              'text/plain': ['.txt'],
              'text/html': ['.html'],
            }}
            maxSize={MAX_FILE_SIZE}
          />
        </>
      )}
      {page === 1 && (
        <ESignatureOptions
          documentType={state.documentType}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
    </Modal>
  );
}
