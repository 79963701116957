import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import {
  ESIGNATURE_HOST_API_BASE_URL,
  EMPLOYEE_SEARCH_PAGINATION_LIMIT
} from '../Common/Constants';
import { Document, Employee, Signer, PaginatedResponse } from '../Common/Types'
import { SignerRole } from '../Common/Enums'
import { getAccessToken, getCompanyId, getEmployeeId, getEmployeeSearchUri, getAppApiBaseUrl, getUserContextId } from './sessionStorageItems';

const getRequestHeaders = (): any => {

  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken,
    'User-Context-Id': getUserContextId(),
  }
}

const employeeSearchUri = getEmployeeSearchUri()?.toString();
const getSearchEmployeesEndpointUri = (companyId: string, firstName: string, lastName: string, limit: number) => {
  let employeeSearchUriRes = employeeSearchUri
    ?.replace('{companyId}', companyId)
    .replace('{firstName}', firstName)
    .replace('{lastName}', lastName)
    .replace('{limit}', `${limit}`);

  if (!firstName) {
    employeeSearchUriRes = employeeSearchUriRes?.replace('firstName=&', '');
  }
  if (!lastName) {
    employeeSearchUriRes = employeeSearchUriRes?.replace('lastName=&', '');
  }

  return employeeSearchUriRes;
}

const downloadDocument = (url: string, fileName: string) => {
  // Attach it to a hidden anchor tag
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  // Click hidden tag to begin download
  link.click();
  // Clean up and remove link
  link.parentNode?.removeChild(link);
}

const companyId = getCompanyId();
const employeeId = getEmployeeId();
const accessToken = getAccessToken();

export const isCompanyIdSet = (): Boolean =>  {
  const tempCompanyId = getCompanyId();
  if (tempCompanyId === null || tempCompanyId === undefined) {
    return false;
  } else if (tempCompanyId?.toString().trim() === '') {
    return false;
  } else {
    return true;
  }
}

export const isEmployeeIdSet = (): Boolean => {
  const tempEmployeeId = getEmployeeId();
  if (tempEmployeeId === null || tempEmployeeId === undefined) {
    return false;
  } else if (tempEmployeeId?.toString().trim() === '') {
    return false;
  } else {
    return true;
  }
}

export const isAccessTokenSet = (): Boolean => {
  const tempAccessToken = getAccessToken();
  if (tempAccessToken === null || tempAccessToken === undefined) {
    return false;
  } else if (tempAccessToken?.toString().trim() === '') {
    return false;
  } else {
    return true;
  }
}

export const isAppApiBaseUrlSet = (): Boolean => {
  const tempAppApiBaseUrl = getAppApiBaseUrl();
  if (tempAppApiBaseUrl === null || tempAppApiBaseUrl === undefined) {
    return false;
  } else if (tempAppApiBaseUrl?.toString().trim() === '') {
    return false;
  }

  return true;
}

export const isSearchEmployeesEndpointUriSet = (): Boolean => {
  const tempEmployeeSearchUri = getEmployeeSearchUri();
  if (tempEmployeeSearchUri === null || tempEmployeeSearchUri === undefined) {
    return false;
  } else if (tempEmployeeSearchUri?.toString().trim() === '') {
    return false;
  }

  return true;
}

export async function createCompanyDocument(
  fileTitle: string,
  file: File | undefined,
  isEsig: boolean,
   
) {
  return await axios
    .post(
      `${
        process.env.REACT_APP_ESIGNATURE_API_BASE_URL
      }/companies/${companyId}/company-documents${
        isEsig ? '?is-esig=true' : ''
      }`,
      {
        data: {
          type: 'company-document',
          attributes: {
            documentName: fileTitle,
            companyId: companyId,
            mimeType: file?.type,
          },
        },
      },
      {
        headers: getRequestHeaders()
      }
    )
    .then((response: AxiosResponse) => {
      return {
        s3PresignedUrl: response?.data?.data?.s3PresignedUrl,
        documentId: response?.data?.data?.documentId,
      };
    })
    .catch((error: AxiosError) => {
      return error
    });
}

export async function createEmployeeDocument(
  fileTitle: string,
  file: File | undefined,
  isEsig: boolean
) {
  return await axios
    .post(
      `${
        process.env.REACT_APP_ESIGNATURE_API_BASE_URL
      }/companies/${companyId}/employees/${employeeId}/employee-documents${
        isEsig ? '?is-esig=true' : ''
      }`,
      {
        data: {
          type: 'employee-document',
          attributes: {
            documentName: fileTitle,
            mimeType: file?.type,
          },
        },
      },
      {
        headers: getRequestHeaders()
      }
    )
    .then((response: AxiosResponse) => {
      return {
        s3PresignedUrl: response?.data?.data?.s3PresignedUrl,
        documentId: response?.data?.data?.documentId,
      };
    })
    .catch((error: AxiosError) => {
       return error;
    });
}

export async function uploadFileToS3(
  s3PresignedUrl: string,
  file: File | undefined,
   
) {
  return await axios
    .put(s3PresignedUrl, file, {})
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error: AxiosError) => {
       return error;
    });
}

export async function getDocumentEditUrl(
  documentId: string,
   
) {
  axiosRetry(axios, {
    retries: 9,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => {
      return retryCount * 10000;
    },
    retryCondition: () => true,
  });
  return await axios
    .post(
      `${process.env.REACT_APP_ESIGNATURE_API_BASE_URL}/companies/${companyId}/company-documents/${documentId}/edit-url`,
      {
        data: {},
      },
      {
        headers: getRequestHeaders()
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
       return error;
    });
}

export async function getDocumentSignUrl(
  documentId: string
) {
  return await axios
    .post(
      `${process.env.REACT_APP_ESIGNATURE_API_BASE_URL}/companies/${companyId}/employees/${employeeId}/employee-documents/${documentId}/sign-url`,
      {
        data: {},
      },
      {
        headers: getRequestHeaders()
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

export async function listCompanyDocument(
  direction?: string
) {
  let path = direction
    ? direction
    : `/companies/${companyId}/company-documents?limit=15`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;
  return await axios
    .get(url, {
      headers: getRequestHeaders()
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

export async function listEmployeeDocument(
  direction?: string
) {
  let path = direction
    ? direction
    : `/companies/${companyId}/employees/${employeeId}/employee-documents?limit=15`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;
  return await axios
    .get(url, {
      headers: getRequestHeaders()
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

export async function downloadCompanyDocument(
  documentId: string,
  fileName: string
) {
  let path = `/companies/${companyId}/company-documents/${documentId}/download-url`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;
  return await axios
    .get(url, {
      headers: getRequestHeaders()
    })
    .then((response) => {
      // Pre-signed URL from endpoint
      const url = response.data.url;
      downloadDocument(url, fileName);
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

export async function searchEmployees(query: string): Promise<PaginatedResponse<Employee>> {
  const genericError = 'There was an issue retrieving a list of employees.'
  try {
    const baseUrl = getAppApiBaseUrl();
    if (!baseUrl) {
      console.error(`The host application must be set ${ESIGNATURE_HOST_API_BASE_URL} in sessionStorage`);
      throw genericError;
    }
    if (!companyId) {
      console.error('No companyId found in sessionStorage');
      throw genericError;
    }

    const [firstName, lastName] = query.split(' ');

    let path = getSearchEmployeesEndpointUri(companyId, firstName, lastName, EMPLOYEE_SEARCH_PAGINATION_LIMIT);
    let url = baseUrl + path;
    return await axios
      .get(url, {
        headers: getRequestHeaders()
      })
      .then((response) => {
        return response.data as PaginatedResponse<Employee>;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  } catch (e) {
    throw e;
  }
}

export async function assignSignatureDocumentsToEmployees(
  errorHandling: Function,
  document: Document,
  employees: Employee[],
  subject: string,
  message: string,
  allowDecline: boolean,
) {
  let path = `/companies/${companyId}/company-documents/${document.documentId}/assign-company-document`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;

  const body = {
    data: {
      type: 'assign-e-signature-document',
      attributes: {
        title: document.documentName,
        subject,
        message,
        signerList: employees.map(ee => ({
          role: SignerRole.Employee,
          firstName: ee.firstName,
          lastName: ee.lastName,
          employeeId: ee.id,
          emailAddress: ee.email,
        } as Signer)),
        allowDecline,
      },
    },
  }

  return await axios
    .post(
      url,
      body,
      {
        headers: getRequestHeaders()
      },
    )
    .catch((error: AxiosError) => {
       errorHandling(error)
    });
}

export async function bulkAssignSignatureDocumentsToEmployees(
    errorHandling: Function,
    document: Document,
    subject: string,
    message: string,
    allowDecline: boolean,
) {
  let path = `/companies/${companyId}/company-documents/${document.documentId}/bulk-assign`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;

  let employeeSearchBaseUri = getSearchEmployeesEndpointUri(companyId, '', '', EMPLOYEE_SEARCH_PAGINATION_LIMIT);

  const body = {
    data: {
      type: 'bulk-assignment',
      attributes: {
        documentAssignment:{
          title: document.documentName,
          subject,
          message,
          signerList: [],
          allowDecline,
        },
        employeeSearchBaseUri,
        employeeSearchBaseUrl: getAppApiBaseUrl(),
      }
    }
  }

  return await axios
      .post(
          url,
          body,
          {
            headers: getRequestHeaders()
          },
      )
      .catch((error: AxiosError) => {
        errorHandling(error)
      });
}
  
export async function downloadEmployeeDocument(documentId: string, fileName: string) {
  let path = `/companies/${companyId}/employees/${employeeId}/employee-documents/${documentId}/download-url`
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;
  return await axios
    .get(url, {
      headers: getRequestHeaders()
    })
    .then((response) => {
      // Pre-signed URL from endpoint
      const url = response.data.url;
      downloadDocument(url, fileName);
    })
    .catch((error: AxiosError) => {
       return error;
    });
}

export async function shareDocumentsToEmployees(
  errorHandling: Function,
  document: Document,
  employees: Employee[],
  subject: string,
  message: string,
) {
  let path = `/companies/${companyId}/company-documents/${document.documentId}/share-company-document`;
  let url = process.env.REACT_APP_ESIGNATURE_API_BASE_URL + path;

  const body = {
    data: {
      type: 'share-company-document',
      attributes: {
        id: document.documentId,
        title: document.documentName,
        subject,
        message,
        assignerList: employees.map(ee => ({
          employeeId: ee.id,
        })),
      },
    },
  }

  return await axios
    .post(
      url,
      body,
      {
        headers: getRequestHeaders()
      },
    )
    .catch((error: AxiosError) => {
      errorHandling(error);
    });
}