import { rest } from 'msw';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
export const success_create_document_response = rest.post('*/companies/:companyId/company-documents',
(req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      documentId: '1',
      s3PresignedUrl: 'https://phonyurl.com/12345'
    })
  )
  })
export const success_company_document_response = rest.get(
  '*/companies/:companyId/company-documents',
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        links: {
          first:
            '/companies/60001/company-documents?cursor=eyJkaXJlY3Rpb24iOiJmaXJzdCJ9&limit=15',
          previous: '',
          next: '/companies/60001/company-documents?cursor=eyJkaXJlY3Rpb24iOiJuZXh0IiwiZXZhbHVhdGVkS2V5Ijp7ImlkIjp7IlMiOiIxNjA1MmEwOS01OTJmLTQ4ZjctOTY0Mi04MGRhZGY2ZTJhOTkifSwiY29tcGFueUlkIjp7IlMiOiI2MDAwMSJ9LCJkb2N1bWVudElkIjp7IlMiOiIzMDFjNzFmZC0wNTg3LTRkZTctOTgwOS0yMGY1NjMwOTNhZDUifX19&limit=15',
          last: '/companies/60001/company-documents?cursor=eyJkaXJlY3Rpb24iOiJsYXN0In0=&limit=15',
        },
        meta: {
          page: {
            total: 15,
          },
        },
        data: [
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-24T19:06:06.547Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/03db682e-53a5-433c-a422-009f5af3b7de.pdf',
            documentName: 'Test - 755',
            documentId: '03db682e-53a5-433c-a422-009f5af3b7de',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-24T19:03:45.848Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/0b438547-d437-4ce7-a00e-86ed94c7ad64.pdf',
            documentName: 'Test - 745',
            documentId: '0b438547-d437-4ce7-a00e-86ed94c7ad64',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-24T18:42:57.456Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/0cb2c755-2f6d-44bb-85e2-27dbc7a0e5f6.txt',
            documentName: 'Test - 605',
            documentId: '0cb2c755-2f6d-44bb-85e2-27dbc7a0e5f6',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-25T16:10:21.445Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/0e140f0f-c264-4e0f-b8a4-429be822c581.pdf',
            documentName: 'Test 3120',
            documentId: '0e140f0f-c264-4e0f-b8a4-429be822c581',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-17T17:03:12.375Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/12bcf4ce-9296-467a-92d5-481953b6caf8.txt',
            documentName: 'Test - 102',
            documentId: '12bcf4ce-9296-467a-92d5-481953b6caf8',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-24T18:38:20.376Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/13c64901-f66c-4603-90bb-10f60c2048d2.txt',
            documentName: 'Test - 520',
            documentId: '13c64901-f66c-4603-90bb-10f60c2048d2',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-24T19:00:14.746Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/14b16184-4304-491f-a07f-a810fa3a2ffa.pdf',
            documentName: 'Test - 745',
            documentId: '14b16184-4304-491f-a07f-a810fa3a2ffa',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-25T14:46:48.207Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/18784f2a-5271-43b8-b9c7-6b4bcdeb4cf1.pdf',
            documentName: 'Test - 2000',
            documentId: '18784f2a-5271-43b8-b9c7-6b4bcdeb4cf1',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-17T17:47:29.774Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/1d13d9f9-bbec-4711-bcd1-16a01dcb0c4e.txt',
            documentName: 'Test - 125',
            documentId: '1d13d9f9-bbec-4711-bcd1-16a01dcb0c4e',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-17T16:15:40.270Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/23e9f510-6d1b-42e6-b5f8-d660b61c5d03.txt',
            documentName: 'Test - 70',
            documentId: '23e9f510-6d1b-42e6-b5f8-d660b61c5d03',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-17T14:52:59.347Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/26f35d48-6607-47d6-83b3-d605307961a0.txt',
            documentName: 'Test - 53',
            documentId: '26f35d48-6607-47d6-83b3-d605307961a0',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-18T18:00:57.825Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/29aad4f3-e4ff-4b5a-bebb-81c6dcf6c47e.pdf',
            documentName: 'Test - 225',
            documentId: '29aad4f3-e4ff-4b5a-bebb-81c6dcf6c47e',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'application/pdf',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-17T14:34:31.689Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/2bc41a97-fdba-4c87-a514-8ad3734739c4.txt',
            documentName: 'Test - 52',
            documentId: '2bc41a97-fdba-4c87-a514-8ad3734739c4',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: false,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-25T16:56:56.000Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/2f3a3deb-63b8-4bf7-867a-794dc369430a.txt',
            documentName: 'loadindJJP',
            documentId: '2f3a3deb-63b8-4bf7-867a-794dc369430a',
            documentStatus: 'ACTIVE',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: true,
          },
          {
            recordType: 'COMPANY',
            createdDate: '2023-01-23T16:44:33.419Z',
            associatedProject: 'ASURE_HCM',
            s3Key: '60001/301c71fd-0587-4de7-9809-20f563093ad5.txt',
            documentName: 'Test - 315',
            documentId: '301c71fd-0587-4de7-9809-20f563093ad5',
            documentStatus: 'DRAFT',
            companyId: '60001',
            mimeType: 'text/plain; charset=utf-8',
            isEsignature: false,
          },
        ],
      })
    );
  }
);
export const failed_company_document_response = rest.get(
  '*/companies/:companyId/company-documents',
  (req, res, ctx) => {
    return res(ctx.status(400));
  }
);

export const success_employee_document_response = rest.get(
  '*/companies/*/employees/*/employee-documents',
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        links: {
          first:
            '/companies/11/employees/asurehcm@DEV50HT_SWHD@2@2345/employee-documents?cursor=eyJkaXJlY3Rpb24iOiJmaXJzdCJ9&limit=15',
          previous: '',
          next: '/companies/11/employees/asurehcm@DEV50HT_SWHD@2@2345/employee-documents?cursor=eyJkaXJlY3Rpb24iOiJuZXh0IiwiZXZhbHVhdGVkS2V5Ijp7ImlkIjp7IlMiOiJiZDlhOWNhNC01MTFmLTRmMWEtOGU5Yy0xZTYxOWJjNWY5OGMifSwiZW1wbG95ZWVJZCI6eyJTIjoiYXN1cmVoY21AREVWNTBIVF9TV0hEQDJAMjM0NSJ9LCJkb2N1bWVudElkIjp7IlMiOiI5ZjVmNTBiOS0zZDM5LTQ5OTktODg1ZS00Mjc2YTJmN2IyMzIifX19&limit=15',
          last: '/companies/11/employees/asurehcm@DEV50HT_SWHD@2@2345/employee-documents?cursor=eyJkaXJlY3Rpb24iOiJsYXN0In0=&limit=15',
        },
        meta: {
          page: {
            total: 15,
          },
        },
        data: [
          {
            id: '512bf4c3-34e3-4171-a373-4c76ac6ad56d',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/18fb6b1b-424c-4032-b65d-2021e2f2929f.pdf',
            documentName: '0garbage #12 doc',
            documentId: '18fb6b1b-424c-4032-b65d-2021e2f2929f',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '8695a52a-c036-4eff-9cb1-ab74466eac18',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/1eccf669-4f35-4cf9-a96b-f78537881aee.pdf',
            documentName: '0garbage #14 doc',
            documentId: '1eccf669-4f35-4cf9-a96b-f78537881aef',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'a3931734-fc24-4360-83dd-069257ffa51d',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/1eccf669-4f35-4cf9-a96b-f78537881aee.pdf',
            documentName: '0garbage #13 doc',
            documentId: '1eccf669-4f35-4cf9-a96b-f78537881aea',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '864853f6-322c-427d-a50d-ab5068c8558e',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/4d9e1e02-23e2-48b6-80ca-ba4e72d11dc8.pdf',
            documentName: '0garbage #2 doc',
            documentId: '22d38a37-256d-4f0d-9f5c-80f4755453d9',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'e4ab4b47-c7fe-4410-a4b1-53dc32976014',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/2420e77e-ebaa-4021-b1df-4fa1878c4920.pdf',
            documentName: '0garbage #10 doc',
            documentId: '2420e77e-ebaa-4021-b1df-4fa1878c4920',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '1850a543-cd40-484c-8edf-df61685c9008',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/4bdaf842-e259-419b-907c-6034addd6681.pdf',
            documentName: '0garbage #17 doc',
            documentId: '4bdaf842-e259-419b-907c-6034addd6681',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'e5c9edc0-59ff-4c20-9e10-38a4e8eccfb3',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/4d9e1e02-23e2-48b6-80ca-ba4e72d11dc8.pdf',
            documentName: '0garbage #1 doc',
            documentId: '4d9e1e02-23e2-48b6-80ca-ba4e72d11dc8',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '118232e0-2c54-439f-9d4c-1eeb7e5ea7ca',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/533bd898-16c9-4d81-8d3d-de515f269e3c.pdf',
            documentName: '0garbage #5 doc',
            documentId: '533bd898-16c9-4d81-8d3d-de515f269e3c',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '0fc9bd54-3087-40f8-a3e1-961f4121f909',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/7187bb20-0d01-4c4e-b433-34b3f138004c.pdf',
            documentName: '0garbage #11 doc',
            documentId: '7187bb20-0d01-4c4e-b433-34b3f138004c',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '8e6834ad-ec16-4951-a48b-e0be440f0669',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/78d0c7bc-f951-4c74-a9d8-93cd6a3ed35b.pdf',
            documentName: '0garbage #15 doc',
            documentId: '78d0c7bc-f951-4c74-a9d8-93cd6a3ed35b',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '080c377d-cd7e-4e3a-b575-00c12ec51ec0',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/7c0a7e8a-2357-4ac3-928b-b59837ba0d8a.pdf',
            documentName: '0garbage #8 doc',
            documentId: '7c0a7e8a-2357-4ac3-928b-b59837ba0d8a',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'd4c8af5e-8b79-4061-8b59-b1f327b69133',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/7c8e4289-493c-4749-8b96-b64590ccd3ae.pdf',
            documentName: '0garbage #9 doc',
            documentId: '7c8e4289-493c-4749-8b96-b64590ccd3ae',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: '8a2ea64e-19c7-4b2b-91d1-eb8f2ea693c4',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/4d9e1e02-23e2-48b6-80ca-ba4e72d11dc8.pdf',
            documentName: '0garbage #4 doc',
            documentId: '9e46a531-62ec-4102-ad38-26a3980fced2',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'e56b9345-e161-4b99-b787-659a31b4b1ec',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/4d9e1e02-23e2-48b6-80ca-ba4e72d11dc8.pdf',
            documentName: '0garbage #3 doc',
            documentId: '9e46a531-62ec-4102-ad38-26a3980fced3',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
          {
            id: 'bd9a9ca4-511f-4f1a-8e9c-1e619bc5f98c',
            recordType: 'EMPLOYEE',
            createdDate: '2022-12-21T14:58:02.739Z',
            associatedProduct: 'ASURE_HCM',
            s3Key: '11/9f5f50b9-3d39-4999-885e-4276a2f7b232.pdf',
            documentName: '0garbage #16 doc',
            documentId: '9f5f50b9-3d39-4999-885e-4276a2f7b232',
            documentStatus: 'ACTIVE',
            tenantId: '40a6087a-6e3f-403c-9ce3-e0726ea34b56',
            companyId: '11',
            mimeType: 'pdf',
            employeeId: 'asurehcm@DEV50HT_SWHD@2@2345',
          },
        ],
      })
    );
  }
);

export const failed_employee_document_response = rest.get(
  '*/employee-documents',
  (req, res, ctx) => {
    return res(ctx.status(400));
  }
);

export const success_company_document_download = rest.get(
  '*/download-url',
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        url: 'https://www.testpresignedurl.com'
      })
    )
  }
);

export const success_company_employee_response = rest.get(
  '*/companies/:companyId/employees',
  async (req, res, ctx) => {
    const eeCount = 55;
    await delay(3000);

    const firstName = req.url.searchParams.get('firstName');
    const lastName = req.url.searchParams.get('lastName');
    const limit = req.url.searchParams.get('limit');
    const data = [...Array(eeCount).keys()].map((i) => ({
      id: `asurehcm@DEV50HT_SWHD@2@2345${i}`,
      firstName: `EE ${i}`,
      lastName: 'Test',
      email: `test${i}@test.com`,
    }))
    
    const filtered = firstName || lastName ? data.filter((ee) => {
      return ee.firstName.includes(firstName) || firstName.includes(ee.firstName)
      || ee.lastName.includes(lastName) || lastName.includes(ee.lastName)
    }) : data;

    const limited = filtered.slice(0, limit);

    return res(
      ctx.status(200),
      ctx.json({
        meta: {
          count: limited.length,
          totalCount: eeCount,
        },
        data: limited,
      })
    );
  }
);

export const success_assign_document_response = rest.post(
  '*/companies/:companyId/company-documents/:documentId/assign-company-document',
  async (req, res, ctx) => {
    await delay(2000);
    return res(
      ctx.status(201),
    );
  }
);
export const fakePut = rest.put('https://phonyurl.com/12345',
  async (req, res, ctx) => {
    return res(ctx.status(200));
  }
)
export const getEditUrl = rest.get('*/companies/8192/company-documents/:companyId/edit-url',
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        url: 'https://www.testpresignedurl.com'
      })
    )
  }
)
export const user_admin_context = rest.get(
  '*/user-context/:contextId',
  (req, res, ctx) => {
  return res (
    ctx.status(200),
    ctx.json({
        role: 'ADMIN',
        companyId: ['8192'],
        employeeIds: ['asurehcm@DEV50HT_SWHD@1000@101','asurehcm@DEV50HT_SWHD@17@1234','asurehcm@DEV50HT_SWHD@2@2345']
    })
  )
  }
);
export const user_manager_context = rest.get(
  '*/user-context/:contextId',
  (req, res, ctx) => {
  return res (
    ctx.status(200),
    ctx.json({
        role: 'MANAGER',
        companyId: ['8192'],
        employeeIds: ['asurehcm@DEV50HT_SWHD@1000@101','asurehcm@DEV50HT_SWHD@17@1234','asurehcm@DEV50HT_SWHD@2@2345']
    })
  )
  }
);
export const user_employee_context = rest.get(
  '*/user-context/:contextId',
  (req, res, ctx) => {
  return res (
    ctx.status(200),
    ctx.json({
        role: 'EMPLOYEE',
        companyId: ['8192'],
        employeeIds: ['asurehcm@DEV50HT_SWHD@1000@101','asurehcm@DEV50HT_SWHD@17@1234','asurehcm@DEV50HT_SWHD@2@2345']
    })
  )
  }
);

export const handlers = [
  fakePut,
  success_create_document_response,
  success_company_document_response,
  failed_company_document_response,
  success_employee_document_response,
  failed_employee_document_response,
  success_company_document_download,
  success_company_employee_response,
  success_assign_document_response,
  user_admin_context
];
