export enum DocumentStatus {
  PendingUpload = 'PENDING_UPLOAD',
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Pending = 'PENDING'
}

export enum SignatureStatus {
  PendingSignature = 'PENDING_SIGNATURE',
  RecordCreated = 'SIGNER_RECORD_CREATED'
}
