import { useState} from 'react';
import { useDocumentState } from '../../Context/DocumentContext';
import { LoadingIndicator, Spinner } from '@asuresoftware/asure.design-system';
import { Document } from '../../Common/Types';
import TableHead from './TableHead';
import CompanyTableRow from './CompanyTableRow';
import EmployeeTableRow from './EmployeeTableRow';
import './TableBody.scss';

export interface TableProps {
  emptyResultsMessage: string;
  className?: string;
  setDocumentRedirect: (documentRedirect: any) => void;
}

export interface TableRow {
  [key: string]: JSX.Element;
}

export interface Columns {
  name?: string;
}

export default function Table({
  emptyResultsMessage,
  setDocumentRedirect
}: TableProps) {
  const [columns] = useState([{ name: 'Document' }, { name: '' }]);
  const { state } = useDocumentState();

  const TableRow = state.documentType === 'employee' ? EmployeeTableRow : CompanyTableRow;
  return (
    <>
      {state.loading || !state.documentList ? (
        <LoadingIndicator className="m-3">Loading</LoadingIndicator>
      ) : (
        <>
          {state.documentList?.data.length === 0 ? (
            <span>{emptyResultsMessage}</span>
          ) : (
            <table>
              <TableHead columns={columns} />
              <tbody className="table-body">
                {state.documentList.data
                  .filter(
                    (item: Document) =>
                      state.pendingDocuments?.includes(item.documentId) ||
                      item.documentStatus !== 'PENDING_UPLOAD'
                  )
                  .map((item: Document) => (
                    <TableRow
                      document={item}
                      key={item.documentId}
                      setDocumentRedirect={setDocumentRedirect}
                    />
                  ))}
              </tbody>
            </table>
          )}
          <div className="w-100 p-3 text-center">
            {state.fetching && (
              <div className="w-100 mt-3 mb-5 text-center d-flex flex-row justify-content-center align-items-center fetching">
                <Spinner size="sm" className="mr-3" />
                Loading Documents...
              </div>
            )}
            {!state.fetching && !state.documentList.links['next'] && (
              <p className="p-2">End of Documents</p>
            )}
          </div>
        </>
      )}
    </>
)}
