import {
  IndeterminateButton,
  IconComponent,
} from '@asuresoftware/asure.design-system';
import { DateTime } from 'luxon';
import { Document } from '../../Common/Types/Document';
import { downloadEmployeeDocument } from '../../Services/services';
import {
  useDocumentState,
  setCurrentDocument,
  setDownloaded,
} from '../../Context/DocumentContext';
import { SignatureStatus } from '../../Common/Enums';

export interface EmployeeTableRowProps {
  document: Document;
  setDocumentRedirect: (documentRedirect: any) => void;
}

export default function EmployeeTableRow({
  document,
  setDocumentRedirect
}: EmployeeTableRowProps) {
  const { state, dispatch } = useDocumentState();
  const openDocumentSign = () => {
    dispatch(setCurrentDocument(document.documentId));
    setDocumentRedirect({
      url: '/document',
      state: {
        documentId: document.documentId,
        method: 'sign',
        fileTitle: document.documentName,
      },
    });
  };
  return (
    <tr
      className="border-bottom"
      key={`${document.documentId}_${document.s3Key}_${document.createdDate}`}
    >
      <td className="w-100">
        <div className="d-flex flex-column justify-sm-content-start justify-content-between">
        <div className="text-primary">{document.documentName}</div>
          {document.documentName.length > 62 && (<span className="tooltip">{document.documentName}</span>) }
          <span className="text-muted">
            {document.documentStatus === SignatureStatus.PendingSignature
              ? `Assigned on ${DateTime.fromISO(document.createdDate).toFormat(
                  'LLLL dd, yyyy'
                )}`
              : `Uploaded on ${DateTime.fromISO(document.createdDate).toFormat(
                  'LLLL dd, yyyy'
                )}`}
          </span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column align-items-center justify-content-center">
          {((document.documentStatus === SignatureStatus.PendingSignature &&
            state.pendingDocuments &&
            state.pendingDocuments.includes(document.documentId)) ||
            document.documentStatus === SignatureStatus.RecordCreated) && (
            <div className="badge badge-info mr-2">Pending</div>
          )}
          {document.documentStatus === SignatureStatus.PendingSignature &&
            (!state.pendingDocuments ||
              !state.pendingDocuments.includes(document.documentId)) && (
              <IndeterminateButton
                data-testid="sign-button"
                size="sm"
                onClick={() => openDocumentSign()}
                className="circle-button"
                title="Sign document"
              >
                <IconComponent icon="pen-alt" lib="fal" size="2x" />
              </IndeterminateButton>
            )}
          {document.documentStatus === 'ACTIVE' && (
            <button
              className={`circle-button btn-download`}
              data-testid="downloadEmployeeDoc"
              onClick={async (e: any) => {
                dispatch(setDownloaded(false));
                e.target.classList.add('loading');
                await downloadEmployeeDocument(
                  document.documentId,
                  document.documentName
                ).then(() => {
                  dispatch(setDownloaded(true));
                  setTimeout( () => { dispatch(setDownloaded(false)) }, 1000);
                  e.target.classList.remove('loading');  
                });
              }}
              title="Download document"
            >
              <IconComponent icon="download" size="lg" lib="fal" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}
