import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClientState } from '../../Context/ClientContext';
import {
  useDocumentState,
  addPendingDocument,
  setPollCount,
  setCreated,
  setDownloaded,
  setAssigned,
  setShared,
} from '../../Context/DocumentContext';
import {
  AsureCloudToastGroup,
  AsureCloudToast,
} from '@asuresoftware/asure.design-system';
import './EditComponent.scss';

export interface EditComponentProps {
  esignUrl: string;
  setIframeLoaded: (iframeLoaded: boolean) => void;
}
export function EditComponent({
  esignUrl,
  setIframeLoaded,
}: EditComponentProps) {
  const [timerComplete, setTimerComplete] = useState<boolean>(false);
  const navigate = useNavigate();
  const location =  useLocation();

  const { clientState } = useClientState();
  const { state, dispatch } = useDocumentState();
  
  useEffect(() => {
    if (clientState.client) {
      clientState.client.open(esignUrl, {
        container: document.getElementById('esignature-container') as
          | HTMLElement
          | undefined,
        testMode: true,
        skipDomainVerification: true,
      });
      if (clientState.client.isOpen) {
        dispatch(setCreated(false));
        dispatch(setDownloaded(false));
        dispatch(setAssigned(false));     
        dispatch(setShared(false));  
        setIframeLoaded(true);
      }
    }
  }, [clientState, esignUrl, setIframeLoaded]);
  useEffect(() => {
    if (clientState.client) {
      clientState.client.on('finish', function () {
        dispatch(setCreated(true));
        dispatch(addPendingDocument(state.currentDocument));
        dispatch(setPollCount(0));
        navigate('/');       
      });
      clientState.client.on('sign', function () {
        dispatch(addPendingDocument(state.currentDocument));
        dispatch(setPollCount(0));
        navigate('/employee');        
      });
    }
  }, [clientState, location, state.currentDocument,  dispatch, navigate]);
  useEffect(() => {
    if (clientState.client) {
      if (clientState.client.isOpen) {
        /* set timer to post reminder to user at 30 minutes */
        const timer = setTimeout(() => setTimerComplete(true), 30 * 60 * 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [clientState, setTimerComplete]);
  useEffect(() => {
    if(state.clientClosed) {
      clientState?.client?.close();
    }
  }, [state, clientState])
  return (
    <div id="esignature-container">
      {timerComplete && (
        <AsureCloudToastGroup
          horizontalAlignment="right"
          verticalAlignment="bottom"
        >
          <AsureCloudToast timeout={30} kind="warning" title="Time remaining">
            All changes will be lost if template is not saved within 30 minutes.
          </AsureCloudToast>
        </AsureCloudToastGroup>
      )}
    </div>
  );
}
