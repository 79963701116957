import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {  DocumentProvider } from './Context/DocumentContext';
import { createPortal } from 'react-dom';
import DocumentPage from './Pages/Documents';
import EmbeddedSignatureDocument from './Pages/EmbeddedSignatureDocument';

import './App.scss';

function App() {
  const target = document.getElementById('esignature-app');
  const companyTarget = document.getElementById('esignature-app-company');
  const employeeTarget = document.getElementById('esignature-app-employee');
  if (target) {
    return createPortal(
      <DocumentProvider>
        <BrowserRouter>
          <Routes>          
            <Route path="/" element={<DocumentPage type="company" />} />
            <Route path="/company" element={<DocumentPage type="company" />} />
            <Route
              path="/employee"
              element={<DocumentPage type="employee" />}
            />            
            <Route path="/document" element={<EmbeddedSignatureDocument />} />
          </Routes>
        </BrowserRouter>
        </DocumentProvider>,
      target
    );
  }
  if (companyTarget) {
    return createPortal(
      <DocumentProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/company" />} />
            <Route path="/company" element={<DocumentPage type="company" />} />
            <Route path="/document" element={<EmbeddedSignatureDocument />} />
            <Route path="*" element={<Navigate to="/company" />} />
          </Routes>
        </BrowserRouter>
        </DocumentProvider>,
      companyTarget
    );
  }
  if (employeeTarget) {
    return createPortal(
      <DocumentProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/employee" />} />
            <Route
              path="/employee"
              element={<DocumentPage type="employee" />}
            />
            <Route path="/document" element={<EmbeddedSignatureDocument />} />
            <Route path="*" element={<Navigate to="/employee" />} />
          </Routes>
        </BrowserRouter>
        </DocumentProvider>,
      employeeTarget
    );
  }

  return null;
}

export default App;
