import { useState } from 'react';
import './TextArea.scss'

export interface TextAreaProps {
  id: string;
  label: string;
  placeholder: string;
  setValue: Function;
  required?: boolean;
  rows?: number;
}

export default function TextArea({
  id,
  label,
  placeholder,
  setValue,
  required = false,
  rows = 3,
}: TextAreaProps) {
  const [textValue, setTextValue] = useState<string>('');
  const [invalid, setInvalid] = useState<boolean>(false);

  const invalidClass = invalid ? 'invalid' : '';
  const requiredClass = required ? 'required' : '';

  return (
    <div className="text-area">
      <label htmlFor={id} className={`form-label ${requiredClass} ${invalidClass}`}>
        {label}
      </label>
      <textarea
        id={id}
        name={id}
        className="w-100"
        placeholder={placeholder}
        required={required}
        onBlurCapture={(e) => e.target.checkValidity()}
        onInvalid={() => setInvalid(true)}
        onInput={() => invalid && setInvalid(false)}
        rows={rows}
        value={textValue}
        onChange={(e) => {
          setValue(e.target.value);
          setTextValue(e.target.value);
        }}
      />
    </div>
  );
}
