import { AsureCloudButton, Modal } from '@asuresoftware/asure.design-system';
import { useDocumentState, setError, setClientClosed } from '../../Context/DocumentContext';

export interface ConfirmationDialogProps {
  setConfirmed: (confirmed: boolean) => void;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  header: string;
}

export function ConfirmationDialog({
  setConfirmed,
  modalOpen,
  setModalOpen,
  header
}: ConfirmationDialogProps) {
  const {dispatch} = useDocumentState();
  const footer = (
    <div className="d-flex justify-content-end align-items-center w-100">
      <AsureCloudButton
        className="mr-3"
        state="secondary"
        text="No, go back"
        onClick={() => setModalOpen(false)}
      />
      <AsureCloudButton
        state="primary"
        text="Yes, cancel"
        onClick={() => {
          dispatch(setClientClosed(true));
          dispatch(setError(undefined));
          setConfirmed(true);
          setModalOpen(false);
        }}
      />
    </div>
  );
  return (
    <Modal
      header={header}
      footerContent={footer}
      size="sm"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      Changes you made will not be saved.
    </Modal>
  );
}
