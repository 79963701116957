import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useDocumentState,
  getDocuments,
  setDocumentType,
  addNext,
  checkPendingDocuments,
  getUser
} from '../Context/DocumentContext';
import { AxiosError } from 'axios';
import {
  ErrorPage,
  AsureCloudToast,
  AsureCloudToastGroup
} from '@asuresoftware/asure.design-system';
import { JsonApiErrorResponse } from '../Common/Types/Errors';
import Table from '../Components/Table/Table';
import { DocumentPageHeader } from '../Components/Header/DocumentPageHeader';
import { UploadDocumentsModal } from '../Components/UploadDocumentsModal/UploadDocumentsModal';
import { AssignDocumentModal } from '../Components/AssignDocumentModal/AssignDocumentModal';
import './Documents.scss';
export interface DocumentTableProps {
  type: 'company' | 'employee';
}
const DocumentPage = ({ type }: DocumentTableProps) => {
  const [documentRedirect, setDocumentRedirect] = useState<any | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useDocumentState();
  const redirectDocument = useCallback(() => {
    navigate(documentRedirect.url, {
      state: {
        ...documentRedirect.state,
        prevPath: location.pathname,
      }
    });
  }, [documentRedirect, location, navigate])

  useEffect(() => {
    if (documentRedirect) redirectDocument();
  }, [documentRedirect, redirectDocument]);

  const handleScroll = useCallback(async () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom) {
      addNext(state, dispatch);
    }
  }, [state, dispatch]);
  useEffect(() => {
    if (state.documentList && state.documentList.links['next']) {      
      window.addEventListener('scroll', handleScroll);      
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [state.documentList, handleScroll]);

  const fetchDocuments = useCallback(() => {
    dispatch(getDocuments(state, dispatch));
  }, [state, dispatch]);
  const setUser = useCallback(() => {
    dispatch(getUser(state, dispatch))
  }, [state, dispatch])

  const checkPending = useCallback(() => {
    console.log('checkPending Document')
    if (state.pendingDocuments && state.pendingDocuments.length > 0) {
      dispatch(checkPendingDocuments(state, dispatch));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (
      state.pendingDocuments &&
      state.pendingDocuments.length > 0 &&
      state.pollCount < 30 &&
      state.loading === false && (!state.uploading || state.uploading === false)
    )  {
      setTimeout(checkPending, state.pollCount * 5000);
    }
  }, [
    state.pendingDocuments,
    state.pollCount,
    state.loading,
    state.uploading,
    state.downloaded,
    state.assigned,
    state.shared,
    state.created,
    state.editing,
    checkPending,
  ]);
  
  useEffect(() => {
    if (!state.uploading) {
      fetchDocuments();
    }
  }, [state.documentType, state.uploading]);

  useEffect(() => {
    if(!state.user) {
      setUser();
    }
  }, [state.user]);

  useEffect(() => {
    dispatch(setDocumentType(type));
  }, [type, dispatch]);

  return (
    <>
      {state.error ? (
        <ErrorPage
          error={{
            displayMessage:
              state.error instanceof AxiosError
                ? `${state.error.response?.status} Error: ${
                    (state.error.response?.data as JsonApiErrorResponse)
                      ?.message
                  }`
                : state.error,
          }}
        />
      ) : (
        <>
          <DocumentPageHeader />
          <main className="d-flex flex-column align-items-center justify-content-center">
            <Table
              emptyResultsMessage="No Results"
              setDocumentRedirect={setDocumentRedirect}
            />
            <UploadDocumentsModal setDocumentRedirect={setDocumentRedirect} />
            {state.assignmentModal && (
              <AssignDocumentModal document={state.assignmentDocument} />
            )}
            <AsureCloudToastGroup
              horizontalAlignment="right"
              verticalAlignment="bottom"
            >
            {state.uploading && (
              <AsureCloudToast
                kind="info"
                title=""
                leftBorder
                closeDisabled
                hideCloseButton
              >
                <div className={'loader'} title="Uploading document">
                  Uploading
                </div>
              </AsureCloudToast>
            )}
            {(state.downloaded && (
                  <AsureCloudToast
                  kind="info"
                  timeout={3}
                  title=""
                  leftBorder
                  closeDisabled
                  hideCloseButton
                >
                <div title="Downloaded document">
                  Downloaded Document
                </div>
                </AsureCloudToast>
              )
            )}
            {(state.assigned && (
                  <AsureCloudToast
                    kind="info"
                    timeout={3}
                    title=""
                    leftBorder
                    closeDisabled
                    hideCloseButton
                  >
                    <div title="Assigned document">
                      Assigned Document
                    </div>
                  </AsureCloudToast>
              )
            )}
            {(state.shared && (
                  <AsureCloudToast
                    kind="info"
                    timeout={3}
                    title=""
                    leftBorder
                    closeDisabled
                    hideCloseButton
                  >
                    <div title="Shared document">
                      Shared Document
                    </div>
                  </AsureCloudToast>
              )
            )}
            {(state.created && !state.editing && (
                  <AsureCloudToast
                    kind="info"
                    timeout={3}
                    title=""
                    leftBorder
                    closeDisabled
                    hideCloseButton
                  >
                  <div title="Document created">
                    Document Created
                  </div>
                  </AsureCloudToast>
              )
            )}
            {(state.created && state.editing && (
                  <AsureCloudToast
                    kind="info"
                    timeout={3}
                    title=""
                    leftBorder
                    closeDisabled
                    hideCloseButton
                  >
                  <div title="Document edited">
                    Document Edited
                  </div>
                  </AsureCloudToast>
              )
            )}
            </AsureCloudToastGroup>
          </main>
        </>
      )}
    </>
  );
};
DocumentPage.defaultProps = {
  type: 'company',
};
export default DocumentPage;
