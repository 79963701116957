import { DateTime } from 'luxon';
import { IconComponent } from '@asuresoftware/asure.design-system';
import { Document } from '../../Common/Types/Document';
import {
  setAssignmentDocument,
  useDocumentState,
  setAssignModalActive,
  setCurrentDocument,
  setDownloaded,
  setEditing
} from '../../Context/DocumentContext';
import { downloadCompanyDocument } from '../../Services/services';
import { DocumentStatus } from '../../Common/Enums';
import { UserRoles } from '../../Common/Enums/UserRoles';
import { User } from '../../Common/Types/User';

export interface CompanyTableRowProps {
  document: Document;
  setDocumentRedirect: (documentRedirect: any) => void;
}

export default function CompanyTableRow({
  document,
  setDocumentRedirect,
}: CompanyTableRowProps) {
  const { state, dispatch } = useDocumentState();
  return (
    <tr className="border-bottom">
      <td>
        <div className="d-flex flex-column justify-sm-content-start justify-content-between">
          <div className="text-primary">{document.documentName}</div>
          {document.documentName.length > 62 && (<span className="tooltip">{document.documentName}</span>) }
          <span className="text-muted">{`Uploaded on ${DateTime.fromISO(
            document.createdDate
          ).toFormat('LLLL dd, yyyy')}`}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-row align-items-center justify-dm-content-center justify-content-end">
          {document.documentStatus === DocumentStatus.Draft &&
          document.isEsignature &&
          state.pendingDocuments &&
          state.pendingDocuments.includes(document.documentId) ? (
            <div className="badge badge-info mr-2">Pending</div>
          ) : (
            <>
              {document.documentStatus !== DocumentStatus.PendingUpload &&
                document.isEsignature && (state.user as User)?.role === UserRoles.Admin ? (
                <div
                  data-testid={'edit-button'}
                  className="circle-button"
                  onClick={() => {
                    dispatch(setEditing(true));
                    dispatch(setCurrentDocument(document.documentId));
                    setDocumentRedirect({
                      url: '/document',
                      state: {
                        documentId: document.documentId,
                        method: 'edit',
                        fileTitle: document.documentName,
                        documentStatus: document.documentStatus,
                      },
                    });
                  }}
                  title="Edit document"
                >
                  <IconComponent lib="fal" icon="edit" size="lg" />
                </div>
              ) : null}
            </>
          )}

          {document.documentStatus === DocumentStatus.Active && 
            (((state.user as User)?.role === UserRoles.Manager) ||
              (state.user as User)?.role === UserRoles.Admin) ? (
                document.isEsignature ? (
                  <div
                    data-testid='assign-button'
                    className="circle-button"
                    onClick={() => {
                      dispatch(setAssignmentDocument(document));
                      dispatch(setAssignModalActive(true));
                    }}
                    title="Send for signature"
                  >
                    <IconComponent icon="envelope" lib="fal" size="lg" />
                  </div>
                ) : (
                  <div
                    data-testid='share-button'
                    className="circle-button"
                    onClick={() => {
                      dispatch(setAssignmentDocument(document));
                      dispatch(setAssignModalActive(true));
                    }}
                    title="Share document"
                  >
                    <IconComponent icon="share" lib="fal" size="lg" />
                  </div>
              )
          ) : (
            <div></div>
          )}
          {document.documentStatus === DocumentStatus.Active ||
          (document.documentStatus === DocumentStatus.Draft &&
            document.isEsignature) ? (
            <div>
              <button
                className={`circle-button btn-download`}
                data-testid="downloadCompanyDoc"
                title="Download document"
                onClick={async (e: any) => {
                  dispatch(setDownloaded(false));
                  e.target.classList.add('loading');
                  await downloadCompanyDocument(
                    document.documentId,
                    document.documentName
                  ).then(() => {
                    dispatch(setDownloaded(true));
                    setTimeout( () => { dispatch(setDownloaded(false)) }, 1000);
                    e.target.classList.remove('loading');  
                  });
                }}
              >
                <IconComponent icon="download" size="lg" lib="fal" />
              </button>
            </div>
          ) : (
            <div>
              {document.documentStatus === DocumentStatus.PendingUpload ? (
                <div className="badge badge-info mr-2">Pending</div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
