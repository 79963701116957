import {
  AsureCloudButton,
  Modal,
  AsureCloudTextInput,
} from '@asuresoftware/asure.design-system';
import {useEffect, useState} from 'react';
import {
  useDocumentState,
  setError,
  setAssignModalActive,
  setAssigned,
  setShared,
} from '../../Context/DocumentContext';
import { AxiosError } from 'axios';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import { Document, Employee, PaginatedResponse } from '../../Common/Types';
import {
  SEARCH_EMPLOYEES_DEBOUNCE_TIME,
  EMPLOYEE_SELECTION_LIMIT,
} from '../../Common/Constants';
import * as services from '../../Services/services';
import TextArea from '../TextArea/TextArea';
import './AssignDocumentModal.scss';

export interface AssignDocumentModalProps {
  document: Document;
}

type SelectOption = { label: string; value: string };

export function AssignDocumentModal({ document }: AssignDocumentModalProps) {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [employeesInvalid, setEmployeesInvalid] = useState<boolean>(false);
  const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);
  const [openSelectMenu, setOpenSelectMenu] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [allowDecline, setAllowDecline] = useState<boolean>(false);
  const [selectAllEmployee, setSelectAllEmployee] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [employeeLimit] = useState<number>(EMPLOYEE_SELECTION_LIMIT);
  const { state, dispatch } = useDocumentState();

  const clearForm = () => {
    setSelectedOptions([]);
    setSelectedEmployees([]);
    setEmployees([]);
    setSubject('');
    setMessage('');
    setAllowDecline(false);
    setEmployeesInvalid(false);
  };

  useEffect(() => {
    if (selectAllEmployee) {
      setSelectedOptions([]);
    }
  }, [selectAllEmployee]);

  const handleModalClose = () => {
    dispatch(setAssignModalActive(false));
    clearForm();
  };
  const handleError = (error: AxiosError | string) => {
    dispatch(setError(error));
  };
  const handleSend = async () => {
    setSubmitLoading(true);
    if (isEsignature) {
      dispatch(setAssigned(false));
      if (selectAllEmployee) {
        await services.bulkAssignSignatureDocumentsToEmployees(
          handleError,
          document,
          subject,
          message,
          allowDecline
        );
      }
      else {
        await services.assignSignatureDocumentsToEmployees(
          handleError,
          document,
          selectedEmployees,
          subject,
          message,
          allowDecline
        );
      }
      dispatch(setAssigned(true));
      setTimeout( () => { dispatch(setAssigned(false)) }, 1000);
    } else {
      dispatch(setShared(false));
      await services.shareDocumentsToEmployees(
        handleError,
        document,
        selectedEmployees,
        subject,
        message
      );
      dispatch(setShared(true));
      setTimeout( () => { dispatch(setShared(false)) }, 1000);
    }
    handleModalClose();
    setSubmitLoading(false);
  };

  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected);
    const selectedEmployeeIds = selected.map((ee: SelectOption) => ee.value);
    const selectedEmployees: Employee[] = employees.filter((ee: Employee) =>
      selectedEmployeeIds.includes(ee.id)
    );
    setSelectedEmployees(selectedEmployees);
  };

  const debouncedSearch = debounce(async (query) => {
    setDropdownLoading(true);
    setEmployees([]);
    setOpenSelectMenu(true);
    const { data }: PaginatedResponse<Employee> =
      await services.searchEmployees(query);
    setEmployees(data);
    setDropdownLoading(false);
  }, SEARCH_EMPLOYEES_DEBOUNCE_TIME);

  const isEsignature = document && document.isEsignature;
  
  const incompleteForm = isEsignature ? (!selectAllEmployee && selectedEmployees.length === 0) || !subject || !message :  selectedEmployees.length === 0;

  return (
    <Modal
      mobileFullScreen
      className="assign-modal"
      open={state.assignmentModal}
      size="md"
      onClose={handleModalClose}
      header={isEsignature ? 'Send for signatures' : 'Share company document'}
      footerContent={
        <div className="d-flex align-items-center">
          <span className="flex-grow-1 d-flex align-items-center">
            {isEsignature && (
              <>
                <input
                  type="checkbox"
                  name="allow-decline"
                  data-testid="allow-decline"
                  id="allow-decline"
                  checked={allowDecline}
                  onChange={(e) => setAllowDecline(e.target.checked)}
                />
                <label htmlFor="allow-decline" className="form-label pl-2 mb-0">
                  Allow employees to decline
                </label>
              </>
            )}
          </span>
          <div className="d-flex justify-content-end">
            <AsureCloudButton
              state="secondary"
              className="ml-2"
              text="Cancel"
              onClick={() => {
                handleModalClose();
              }}
            />
            <AsureCloudButton
              state="primary"
              text="Send"
              className="ml-2"
              disabled={submitLoading || incompleteForm}
              loading={submitLoading}
              onClick={() => {
                handleSend();
              }}
            />
          </div>
        </div>
      }
    >
      <div className="mb-3">
        <p className="font-weight-bold mb-1">
          {isEsignature ? 'Document to be signed' : 'Document to share'}
        </p>
        <p className="text-muted m-0">{document.documentName}</p>
      </div>
      <div className={`mb-3 ${isEsignature ? '' : 'selectEmployees'}`}>
        <p className="font-weight-bold mb-1">
          {isEsignature ? 'Who needs to sign?' : 'Employees to share with'}
        </p>
        <div>
          <input
              type="checkbox"
              name="select-all-employee"
              id="select-all-employee"
              data-testid="select-all-employee"
              checked={selectAllEmployee}
              onChange={(e) => setSelectAllEmployee(e.target.checked)}
          />
          <label htmlFor="select-all-employee" className="form-label pl-2 mb-0">
            Select All Employees
          </label>
        </div>
        {!selectAllEmployee && (
          <div>
            <label
              htmlFor="employee-select"
              className={`form-label required ${employeesInvalid ? 'invalid' : ''}`}
            >
              Select up to {employeeLimit} employees
            </label>
            <Select
              id="employee-select"
              name="employee-select"
              onChange={handleSelectChange}
              value={selectedOptions}
              options={employees.map((ee) => ({
                label: `${ee.firstName} ${ee.lastName}`,
                value: `${ee.id}`,
              }))}
              isMulti
              isOptionDisabled={() => selectedOptions.length >= employeeLimit}
              isLoading={dropdownLoading}
              placeholder="Search by first and last name"
              menuIsOpen={openSelectMenu}
              isDisabled={selectAllEmployee}
              onBlur={() => {
                setEmployeesInvalid(selectedEmployees.length === 0);
                setOpenSelectMenu(false);
              }}
              onInputChange={(query) => {
                if (!query) {
                  setOpenSelectMenu(false);
                } else {
                  debouncedSearch(query);
                }
              }}
              styles={{
                menuList: (base) => ({
                  ...base,
                  maxHeight: '10rem',
                }),
                control: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  outline: 'none',
                  boxShadow: state.isFocused
                    ? employeesInvalid
                      ? '0px 0px 4px 2px rgba(162, 0, 17, 0.25)'
                      : '0px 0px 4px 2px rgba(0, 108, 147, 0.25)'
                    : 'none',
                  ...(state.isFocused
                    ? { borderColor: employeesInvalid ? '#a20011' : '#006c93' }
                    : {}),
                  ...(employeesInvalid ? { borderColor: '#a20011' } : {}),
                  '&:hover': {
                    ...(employeesInvalid ? { borderColor: '#a20011' } : {}),
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: '#bbb',
                  fontFamily: 'inherit',
                  fontSize: '0.875rem',
                }),
                dropdownIndicator: () => ({ display: 'none' }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
            />
            <small className="text-muted">
              Employees selected: {selectedEmployees.length} / {employeeLimit}
            </small>
          </div>
        )}
      </div>
      { isEsignature && (<div>
        <p className="font-weight-bold mb-1">Email details</p>
        <AsureCloudTextInput
          id="subject-input"
          labelDisplay="Subject"
          placeholder="Email subject"
          type="text"
          inputClassName="w-100 mb-2"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <TextArea
          id="message-input"
          placeholder="Email message"
          required
          rows={3}
          setValue={setMessage}
          label="Message"
        />
      </div>)}
    </Modal>
  );
}
