import { COMPANY_ID, EMPLOYEE_ID, ACCESS_TOKEN, ESIGNATURE_EMPLOYEE_SEARCH_URI, ESIGNATURE_HOST_API_BASE_URL, USER_CONTEXT_ID } from "../Common/Constants";

export const getCompanyId = () => {
  const tempCompanyId = sessionStorage.getItem(COMPANY_ID);
  if (tempCompanyId === null || tempCompanyId === undefined) {
    return '';
  }
  return tempCompanyId;
};

export const getEmployeeId = () => {
  const tempEmployeeId = sessionStorage.getItem(EMPLOYEE_ID);
  if (tempEmployeeId === null || tempEmployeeId === undefined) {
    return '';
  }
  return tempEmployeeId;
};

export const getAccessToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN);
};

export const getEmployeeSearchUri = () => {
  return sessionStorage.getItem(ESIGNATURE_EMPLOYEE_SEARCH_URI);
};

export const getAppApiBaseUrl = () => {
  return sessionStorage.getItem(ESIGNATURE_HOST_API_BASE_URL);
};

export const getUserContextId = () => {
  return sessionStorage.getItem(USER_CONTEXT_ID);
};