import { createContext, useState, useContext, Dispatch, SetStateAction} from 'react';
import HelloSign from 'hellosign-embedded';

export interface IContext {
    client?: HelloSign;
}
const ClientContext = createContext({
  clientState: {} as Partial<IContext>,
  setClientState: {} as Dispatch<SetStateAction<Partial<IContext>>>
})
const client = new HelloSign({
  clientId: `${process.env.REACT_APP_ESIGNATURE_CLIENT_ID}`,
});
const ClientProvider = ({
  children,
  value = {} as IContext,
}: {
  children: React.ReactNode;
  value?: Partial<IContext>;
}) => {
  const initialValue: Partial<IContext> = {
    client: client
  }
  const [clientState, setClientState] = useState(initialValue);

  return (
    <ClientContext.Provider value={{ clientState, setClientState }} >
      {children}
    </ClientContext.Provider>
  );
};

const useClientState = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientState must be used within a ClientContext")
  }
  return context
}

export {ClientProvider, useClientState};