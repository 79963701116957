import RadioButton from '@asuresoftware/asure.design-system/dist/components/asureCloud/radioButton/RadioButton';
import { ESignatureType } from '../UploadDocumentsModal/UploadDocumentsModal';
import './ESignatureOption.scss';

export interface ESignatureOptionProps {
  eSignatureType: ESignatureType;
  selectedOption: ESignatureType;
  onClick: () => void;
  disabled?: boolean;
}

export function ESignatureOption({ eSignatureType, selectedOption, onClick, disabled }: ESignatureOptionProps) {
  let esignatureContents;

  switch (eSignatureType) {
    default:
      esignatureContents = {
        url: `${process.env.REACT_APP_ESIGNATURE_ASSET_BASE_URL}/assets/media/no-sign.svg`,
        altText: 'No signature icon',
        body: (
          <>
            <strong>No signature</strong>
            <p className="m-0">A signature is not required</p>
          </>
        ),
      };
      break;
    case ESignatureType.Enhanced:
      esignatureContents = {
        url: `${process.env.REACT_APP_ESIGNATURE_ASSET_BASE_URL}/assets/media/enhanced-sign.svg`,
        altText: 'Enhanced E-Signature icon',
        body: (
          <>
            <strong>Enhanced E-Signature</strong>
            <p className="m-0">Use a form builder to create a custom signature template for the document.</p>
            <p className="m-0">
              <strong>Note: </strong>You'll be limited to using the document for self service onboarding, unless you add
              Enhanced E-Signature to your plan.
            </p>
          </>
        ),
      };
      break;
  }

  return (
    <div
      data-testid="esignature-option"
      className={`esignature-option border d-flex align-items-center w-100 p-3 mb-3 
        ${(disabled && 'disabled') || ''} 
        ${(eSignatureType === selectedOption && 'selected') || ''}
      `}
      onClick={disabled ? undefined : onClick}
    >
      <RadioButton
        id={`${eSignatureType}-radio-button`}
        name="esignature-options"
        value={eSignatureType}
        checked={eSignatureType === selectedOption}
        disabled={disabled}
        readOnly
      />
      <img className="pl-2" src={esignatureContents.url} alt={`${esignatureContents.altText} icon`} />
      <div className="d-flex flex-column justify-content-center">{esignatureContents.body}</div>
    </div>
  );
}
