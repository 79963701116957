import { Columns } from './Table';
import './TableHead.scss';

export interface TableHeadProps {
  columns: Columns[];
}

export default function TableHead({ columns }: TableHeadProps) {
  return (
    <thead className="responsive tablehead">
      <tr>
        {columns.map((column, index) => (
          <th key={index}>
            {column?.name}
          </th>
        ))}
      </tr>
    </thead>
  );
}
