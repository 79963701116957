import { createContext, useReducer, useContext } from 'react';
import { AxiosError } from 'axios';
import { PaginatedResponse, Document } from '../Common/Types';
import { DocumentStatus } from '../Common/Enums';
import * as services from '../Services/services';
import * as session from '../Services/sessionStorageItems';
import * as user from '../Services/user';
import { ACCESS_TOKEN, COMPANY_ID, EMPLOYEE_ID, ESIGNATURE_EMPLOYEE_SEARCH_URI, ESIGNATURE_HOST_API_BASE_URL } from '../Common/Constants';
import { User } from '../Common/Types/User';

const LOADING = `LOADING`;
const UPLOADING = 'UPLOADING';
const DOWNLOADED = 'DOWNLOADED';
const ASSIGNED = 'ASSIGNED';
const SHARED = 'SHARED';
const CREATED = 'CREATED';
const EDITING = 'EDITING';
const ERROR = `ERROR`;
const DOCUMENT_MODAL = 'DOCUMENT_MODAL';
const ASSIGN_MODAL = 'ASSIGN_MODAL';
const FETCHING = 'FETCHING';
const POLL_COUNT = 'POLL_COUNT';
const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
const ADD_PENDING_DOCUMENT = 'ADD_PENDING_DOCUMENT';
const REMOVE_PENDING_DOCUMENT = 'REMOVE_PENDING_DOCUMENT';
const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
const SET_ASSIGNMENT_DOCUMENT = 'SET_ASSIGNMENT_DOCUMENT';
const SET_DOCUMENTLIST = 'SET_DOCUMENTLIST';
const UPDATE_DOCUMENTLIST = 'UPDATE_DOCUMENTLIST';
const CLIENT_CLOSED = 'CLIENT_CLOSED';
const USER = 'USER';

export const setLoading = (status: boolean) => ({ type: LOADING, status });
export const setUploading = (status: boolean) => ({ type: UPLOADING, status });
export const setDownloaded = (status: boolean) => ({ type: DOWNLOADED, status});
export const setAssigned = (status: boolean) => ({type: ASSIGNED, status});
export const setShared = (status: boolean) => ({type: SHARED, status});
export const setCreated = (status: boolean) => ({type: CREATED, status});
export const setEditing = (status: boolean) => ({type: EDITING, status});
export const setUser = (value: User) => ({type: USER, value})
export const setError = (error: AxiosError | string | undefined) => ({
  type: ERROR,
  error,
});
export const setDocumentModalActive = (status: boolean) => ({
  type: DOCUMENT_MODAL,
  status,
});
export const setAssignModalActive = (status: boolean) => ({
  type: ASSIGN_MODAL,
  status
})
export const setPollCount = (value: number) => ({ type: POLL_COUNT, value });
export const setFetching = (status: boolean) => ({ type: FETCHING, status });
export const setDocumentType = (documentType: 'company' | 'employee') => ({
  type: DOCUMENT_TYPE,
  documentType,
});
export const setClientClosed = (status: boolean) => ({type: CLIENT_CLOSED, status});
export const addPendingDocument = (documentId: string) => ({
  type: ADD_PENDING_DOCUMENT,
  documentId,
});
export const removePendingDocument = (documentId: string) => ({
  type: REMOVE_PENDING_DOCUMENT,
  documentId,
});
export const setCurrentDocument = (document: string) => ({
  type: SET_CURRENT_DOCUMENT,
  document,
});
export const setAssignmentDocument = (document: Document) => ({
  type: SET_ASSIGNMENT_DOCUMENT,
  document
})
export const setList = (serverResponse: PaginatedResponse<Document>) => ({
  type: SET_DOCUMENTLIST,
  serverResponse,
});
export const getUser = async(state: any, dispatch: any) => {
  const userContext = session.getUserContextId() || '';
  if(userContext && !(state.user as User)) {
    const serverResponse = await user.getUser(userContext);
    if(serverResponse) {
      dispatch(setUser(serverResponse))
    }
  }
}
export const getDocuments = async (state: any, dispatch: any) => {
  const errors: string[] = [];
  if (state.documentType !== undefined && !services.isCompanyIdSet()) {
    errors.push(COMPANY_ID);
  }
  if (state.documentType !== undefined && !services.isAccessTokenSet()) {
    errors.push(ACCESS_TOKEN);
  }
  if (state.documentType === 'company') {
    if (!services.isAppApiBaseUrlSet()) {
      errors.push(ESIGNATURE_HOST_API_BASE_URL);
    }
    if (!services.isSearchEmployeesEndpointUriSet()) {
      errors.push(ESIGNATURE_EMPLOYEE_SEARCH_URI);
    }
  } else if (state.documentType === 'employee') {
    if (!services.isEmployeeIdSet()) {
      errors.push(EMPLOYEE_ID);
    }
  }
  if (errors.length > 0) {
    const all_errors = `The host application must set${errors.map((error, x) => {
        return errors.length - 1 === x && errors.length > 1 ? `and ${error}` : ` ${error}`;
    })} in sessionStorage.`.replace(',and ', ' and ');
    dispatch(setError('Documents are unavailable due to an internal application configuration error. Please contact support for assistance.'));
    dispatch(setLoading(false));
    console.log(all_errors);
    throw new Error(all_errors);
  }
  dispatch(setLoading(true));
  if (state.documentType) {
    const serverResponse =
      state.documentType === 'company'
        ? await services.listCompanyDocument()
        : await services.listEmployeeDocument();
    if (serverResponse.data) {
      dispatch(setList(serverResponse));
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      dispatch(setError(serverResponse));
    }
  }
};
export const updateList = (serverResponse: PaginatedResponse<Document>) => ({
  type: UPDATE_DOCUMENTLIST,
  serverResponse,
});
export const addNext = async (state: any, dispatch: any) => {
  dispatch(setFetching(true));
  const serverResponse =
    state.documentType === 'company'
      ? await services.listCompanyDocument(state.documentList.links.next)
      : await services.listEmployeeDocument(state.documentList.links.next);
  if (serverResponse.data) {
    dispatch(updateList(serverResponse));
    dispatch(setFetching(false));
  } else {
    dispatch(setFetching(false));
    dispatch(setError(serverResponse));
  }
};
export const getRefreshedDocuments = async (state: any) => {
  if (state.documentType) {
    const serverResponse =
      state.documentType === 'company'
        ? await services.listCompanyDocument()
        : await services.listEmployeeDocument();
  return serverResponse;
  }
}
export const checkPendingDocuments = async (state: any, dispatch: any) => {
    const serverResponse = await getRefreshedDocuments(state);    
    if (serverResponse.data) {
      if(state.pendingDocuments.length > 0) {
      state.pendingDocuments.forEach((pendingDoc: string) => {
        const thisDocument = serverResponse.data.filter(
          (doc: Document) => doc.documentId === pendingDoc
        );
        if (thisDocument[0] && thisDocument[0].documentStatus === DocumentStatus.Active) {
          const clone = [...state.documentList.data];
          const isInState = clone.some((doc) => doc.documentId === pendingDoc);
          let cloneDocumentList;
          if (isInState) {
            cloneDocumentList = clone.map((document: Document) => {
              if (document.documentId === pendingDoc) {
                return thisDocument[0];
              } else {
                return document;
              }
            });
          } else {
            cloneDocumentList = [...thisDocument, ...clone];
          }
          const updatedData = {
            meta: state.documentList.meta,
            links: state.documentList.links,
            data: cloneDocumentList,
          };
          dispatch(removePendingDocument(thisDocument[0].documentId));
          dispatch(setList(updatedData));
        }
      });
      dispatch(setPollCount(state.pollCount + 1));
    } else {
      dispatch(setPollCount(5))
    }
    } else {
      dispatch(setError(serverResponse));
    }
};

const initialState = {};
function init(initialState: any) {
  return { state: initialState };
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case USER:
      return { ...state, user: action.value };
    case LOADING:
      return { ...state, loading: action.status };
    case UPLOADING:
      return { ...state, uploading: action.status };
    case DOWNLOADED:
      return { ...state, downloaded: action.status };
    case ASSIGNED:
      return { ...state, assigned: action.status };
    case SHARED:
      return { ...state, shared: action.status };
    case CREATED:
      return { ...state, created: action.status };
    case EDITING:
        return { ...state, editing: action.status };      
    case ERROR:
      return { ...state, error: action.error };
    case DOCUMENT_MODAL:
      return { ...state, documentModal: action.status };
    case ASSIGN_MODAL:
      return {...state, assignmentModal: action.status};
    case POLL_COUNT:
      return { ...state, pollCount: action.value };
    case FETCHING:
      return { ...state, fetching: action.status };
    case DOCUMENT_TYPE:
      return { ...state, documentType: action.documentType };
    case CLIENT_CLOSED:
      return { ...state, clientClosed: action.status}
    case ADD_PENDING_DOCUMENT:
      const cloneAdd =
        state.pendingDocuments && state.pendingDocuments.length > 0
          ? [...state.pendingDocuments]
          : [];
      const newPendingDocuments = [...cloneAdd, action.documentId];
      return { ...state, pendingDocuments: newPendingDocuments };
    case REMOVE_PENDING_DOCUMENT:
      const cloneRemove =
        state.pendingDocuments && state.pendingDocuments.length > 0
          ? [...state.pendingDocuments]
          : [];
      const revisedDocList = cloneRemove.filter(
        (item) => item !== action.documentId
      );
      return { ...state, pendingDocuments: revisedDocList };
    case SET_CURRENT_DOCUMENT:
      return { ...state, currentDocument: action.document };
    case SET_ASSIGNMENT_DOCUMENT:
      return {...state, assignmentDocument: action.document};
    case SET_DOCUMENTLIST:
      return { ...state, documentList: action.serverResponse };
    case UPDATE_DOCUMENTLIST:
      const cloneUpdate = [...state.documentList.data];
      const newData = [...cloneUpdate, ...action.serverResponse.data];
      const updatedList = {
        links: action.serverResponse.links,
        meta: action.serverResponse.meta,
        data: newData,
      };
      return { ...state, documentList: updatedList };
    default:
      return state;
  }
}
const DocumentContext = createContext(initialState);

const DocumentProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  return (
    <DocumentContext.Provider value={{ state, dispatch }}>
      {children}
    </DocumentContext.Provider>
  );
};

const useDocumentState = () => {
  const { state, dispatch }: any = useContext(DocumentContext);
  if (!state && !dispatch) {
    throw new Error('useDocumentState must be used within a DocumentContext');
  }
  return { state, dispatch };
};

export { DocumentProvider, useDocumentState };
