import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AsureCloudButton } from '@asuresoftware/asure.design-system';
import { ConfirmationDialog } from './ConfirmationDialog';

export interface EmbeddedSignaturePageHeaderProps {
  fileTitle: string;
  iframeLoaded: boolean;
  documentStatus: string;
  cancelDisabled: boolean;
  onClose?: Function;
}

export function EmbeddedSignaturePageHeader({
  fileTitle,
  iframeLoaded,
  documentStatus,
  cancelDisabled,
  onClose,
}: EmbeddedSignaturePageHeaderProps) {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (confirmed) {
      onClose && onClose();
      navigate(location.state?.prevPath || '/');
    }
  }, [confirmed, onClose, location, navigate]);

  let header = documentStatus === 'DRAFT'
    ? 'Cancel E-signature document creation?'
    : 'Cancel editing E-signature document?'
  
  const isSigningMode = location.state?.method === 'sign';
  header = isSigningMode ? 'Cancel E-signature process?' : header;

  return (
    <div className="modal-header pb-1 pl-4 pr-2 d-flex align-items-center">
      <h1 className="h5 m-0 p-0">{fileTitle}</h1>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <AsureCloudButton
          state="primary"
          text="Cancel"
          onClick={() => setModalOpen(true)}
        />
        {(iframeLoaded && !isSigningMode) && (
          <p className="small text-muted m-0 p-0 mt-2">
            Click continue below to save document
          </p>
        )}
      </div>
      <ConfirmationDialog
        setConfirmed={setConfirmed}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        header={header}
      />
    </div>
  );
}
