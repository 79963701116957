import { useEffect, useCallback, useState } from 'react';
import { ClientProvider } from '../Context/ClientContext';
import { useLocation } from 'react-router-dom';
import * as services from '../Services/services';
import { AxiosError } from 'axios';
import {
  LoadingIndicator,
  IconComponent,
} from '@asuresoftware/asure.design-system';
import { EmbeddedSignaturePageHeader } from '../Components/Header/EmbeddedSignaturePageHeader';
import { EditComponent } from '../Components/EmbeddedSignature/EditComponent';

import './Documents.scss';
import { useDocumentState, setError, setClientClosed } from '../Context/DocumentContext';

const EmbeddedSignatureDocument = () => {
  const location = useLocation();
  const [esignUrl, setEsignUrl] = useState<string>('');
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const { state, dispatch } = useDocumentState();
  const getEsignUrl = useCallback(async () => { 
    dispatch(setClientClosed(false));
    const esignResponse =  location.state?.method === 'edit' ?
      await services.getDocumentEditUrl(state.currentDocument) : 
      await services.getDocumentSignUrl(
       state.currentDocument
      );
    if (esignResponse instanceof AxiosError) {
      dispatch(setError(esignResponse));
    } else {
      setEsignUrl(location.state?.method === 'edit' ? esignResponse.data.editUrl : esignResponse?.signUrl);
    }
  }, [state.currentDocument, location, setEsignUrl, dispatch]);

  useEffect(() => {
      getEsignUrl();
  }, [getEsignUrl, state.currentDocument]);

  return (
    <>
      <EmbeddedSignaturePageHeader
        fileTitle={location.state.fileTitle}
        iframeLoaded={iframeLoaded}
        cancelDisabled={!iframeLoaded && !state.error}
        documentStatus={location.state.documentStatus}
        onClose={() => setEsignUrl('')}
      />
      {state.error?.message &&
        (location.state?.method === 'edit' ? (
          <div className="retry my-5 py-5 mx-auto h-50 w-25 d-flex flex-column align-items-center justify-content-center border border-light">
            <IconComponent lib="fal" icon="redo" size="10x" />
            <button
              className="btn btn-primary asure-cloud btn-lg mt-5"
              onClick={getEsignUrl}
            >
              Retry
            </button>
          </div>
        ) : (
          <h4 className="text-muted py-5 px-5">
            There was an issue loading this signature. Either an error occurred
            or this document has already been signed.
          </h4>
        ))}
      {!state.error?.message && (
        <>
          {esignUrl !== '' ? (
            <ClientProvider>
              <EditComponent
                esignUrl={esignUrl}
                setIframeLoaded={setIframeLoaded}
              />
            </ClientProvider>
          ) : (
            <div className="mt-5 h-100 w-100 d-flex flex-row align-items-center justify-content-center">
              <LoadingIndicator className="m-3">Loading</LoadingIndicator>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EmbeddedSignatureDocument;
