import { useDocumentState, setDocumentModalActive, setDocumentType, setEditing } from '../../Context/DocumentContext';
import { Link } from 'react-router-dom';
import { AsureCloudButton } from '@asuresoftware/asure.design-system';
import { UserRoles } from '../../Common/Enums/UserRoles';
import { User } from '../../Common/Types/User';

export function DocumentPageHeader() {
  const inApp = document.getElementById('esignature-app');
  const {state, dispatch} = useDocumentState();
  return (
    <header className="mt-4 d-flex justify-content-end pb-3 position-relative">
      {inApp && (
        <Link to={state.documentType === 'company' ? '/employee' : '/'}>
          <AsureCloudButton
            state="primary"
            aria-label="Employee"
            onClick={() => dispatch(setDocumentType(state.documentType === 'company' ? 'employee' : 'company'))}
            className="mr-3"
            text={state.documentType === 'company' ? "Employee" : "Company"}
          />
        </Link>
        
      )}
      <AsureCloudButton
        state="primary"
        aria-label="Add Document"
        onClick={() => {
          dispatch(setEditing(false));
          dispatch(setDocumentModalActive(true));
        }}
        disabled={
          state.uploading || state.documentModal || 
            (state.documentType === 'company' && ((state.user as User)?.role !== UserRoles.Admin)) ||
            (state.documentType === 'employee' && ((state.user as User)?.role === UserRoles.Admin))
        }
        text="Add Document"
      />
    </header>
  );
}
